<template>
  <div class="config-app">
    <h2 class="app-title">Redefinir Software</h2>
    <p class="subtitle">Permite <strong>apagar todos os dados atuais</strong> e restaurar as configurações do software de fábrica.</p>

    <div class="app-input-container">
      <u-btn color="red" no-caps label="Redefinir sistema agora" />
      <u-btn color="indigo" no-caps @click="this.config.setup" label="Executar apenas setup" class="m-l" />
    </div>
  </div>
</template>

<script>
import AppMixin from './appMixin'

export default {
  name: "ConfigRedefinir",
  inject: ['config'],
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        }
      ]
    }
  }
}
</script>

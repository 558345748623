<template>
  <div class="config-app">
    <h2 class="app-title">Financeiro</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo financeiro.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper label="Emitir nota de arrematação somente após o recebimento do pagamento do lote">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.notaSomenteAposPagto').value" />
          <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, somente será possível gerar a Nota de Arrematação após o recebimento do pagamento total devido pelo arremate.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Categorias Expandidas">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.categoriasExpandidas').value" />
          <p class="app-legend m-t-xs m-b-none">Se sim, oculta as categorias filhas e somente mostra após expansão pelo usuário.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Lançar vendas dos Lotes de Leilões no Financeiro">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.lancarVendaLoteFinanceiro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Obrigar anexar comprovante registrar como pago um lançamento">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.forcarAnexoAoPagar').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Categoria para destino da receita">
          <categoria-hirerarquia-select v-model="c('financeiro.categoriaLancamento').value" :tipo="1" :search-only-select="false" />
          <p class="app-legend m-t-xs m-b-none">Esta categoria será para o lançamneto principal, detalhes do lançamento, como impostos e taxas, serão lançados de acordo à escolha no cadastro do <a href="/#/configuracoes/taxas">Sistema de Taxas</a> para o leilão.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Enviar avisos por e-mail para movimentações criadas e vencidas do tipo Receita">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('financeiro.avisoReceita').value" />
          <p class="app-legend m-t-xs m-b-none">Ao criar uma nova receita (contas a receber), o sistema envia o aviso para a pessoa pagadora.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Enviar avisos por e-mail para movimentações criadas e vencimento do tipo Despesa">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('financeiro.avisoDespesa').value" />
          <p class="app-legend m-t-xs m-b-none">Ao criar uma nova despesa (contas a pagar), o sistema envia o aviso para o administrador do sistema ou para e-mails específicos.</p>
        </app-label-wrapper>
        <app-label-wrapper label="Enviar avisos de despesas para e-mails adicionais">
          <textarea rows="10" v-model="c('financeiro.avisoDespesa.emails').value" class="sl-textarea">
          </textarea>
          <p class="app-legend m-t-xs m-b-none">Digite um e-mail por linha. Até 10 e-mails</p>
        </app-label-wrapper>
        <app-label-wrapper label="Enviar avisos de despesas por SMS para celulares adicionais">
          <textarea rows="10" v-model="c('financeiro.avisoDespesa.telefones').value" class="sl-textarea">
          </textarea>
          <p class="app-legend m-t-xs m-b-none">Digite um número por linha. DDD+Numero. Até 10 números.</p>
          <p class="app-legend m-b-none font-10">* Cobrança de taxa aplicável</p>
        </app-label-wrapper>

      </div>
      <list-formas-pagamento v-if="active === 'formas-pagamento'" class="app-input-container" />

      <div v-if="active === 'automacao-pagamentos'" class="app-input-container">

        <app-label-wrapper label="Gateway principal para pagamentos">
          <u-select :options="[{label: 'Nenhum', value: '0'},{label: 'Asaas', value: 'asaas'}]" class="app-input" hide-underline v-model="c('financeiro.gateway.default').value" />
          <p class="app-legend m-t-xs m-b-none">Ao enviar uma cobrança pelo sistema, caso a forma de pagamento e banco não seja definido, o sistema automaticamente escolhe o gateway (banco) padrão.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Forma principal para pagamentos">
          <u-select :options="[{label: 'Nenhum', value: '0'},{label: 'Boleto', value: 'boleto'},{label: 'PIX', value: 'pix'},{label: 'Cartão de crédito', value: 'cartao'}]" class="app-input" hide-underline v-model="c('financeiro.metodo.padrao').value" />
          <p class="app-legend m-t-xs m-b-none">Ao definir, caso o sistema envie cobranças e exista integração com instituições bancárias e/ou gateway de pagamentos, o boleto será automaticamente selecionado como padrão.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Integrar cobranças de arremates automaticamente com o gateway principal">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('financeiro.arremates.integrarGateway').value" />
          <p class="app-legend m-t-xs m-b-none">Ao definir como <strong>Sim</strong>, as cobranças enviadas nos arremates serão automaticamente geradas com integração com o <u>Gateway principal</u> e <u>Forma principal de pagamento</u> selecionados. Esta configuração somente irá funcionar se o Gateway e Forma de Pagamento acima estiverem definidos e configurados.</p>
        </app-label-wrapper>

        <div class="m-b">
          <div class="b-b m-b" style="padding-bottom: 10px">
            Integração com o <a href="https://www.asaas.com/" target="_blank" class="font-bold">Asaas</a>
          </div>
          <app-label-wrapper label="Status">
            <u-select :options="[{label: 'Desativado', value: '0'},{label: 'Ativado', value: '1'}]" class="app-input" hide-underline v-model="c('banco.integra.asaas').value" />
            <!--          <p class="app-legend m-t-xs"></p>-->
          </app-label-wrapper>
          <app-label-wrapper label="Credenciais para API">
            <collapse title="Editar credenciais da API" :collapse="false">
              <code-editor v-model="c('banco.asaas.config').value" :languages="[['javascript']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
            </collapse>
          </app-label-wrapper>
          <app-label-wrapper label="URL para Webhooks">
            <u-input readonly class="app-input" hide-underline v-model="asaasUrlWebhook"/>
          </app-label-wrapper>
        </div>

        <div class="m-b">
          <div class="b-b m-b" style="padding-bottom: 10px">
            Integração com o <a href="https://parcelamostudo.com.br" target="_blank" class="font-bold">Parcelamos Tudo</a>
          </div>
          <app-label-wrapper label="">
            <div class="app-legend m-t-xs">
              <div><img width="100" src="https://parcelamostudo.com.br/static/media/logo-header.f774a3edf33089f911d0763d83b0a6a2.svg" /></div>
              <div class="m-t-sm">Permite ao arrematante parcelar o arremate em até 12 vezes no cartão de crédito.</div>
            </div>
          </app-label-wrapper>
          <app-label-wrapper label="Status">
            <u-select :options="[{label: 'Desativado', value: '0'},{label: 'Ativado', value: '1'}]" class="app-input" hide-underline v-model="c('banco.integra.parcelamosTudo').value" />
            <!--          <p class="app-legend m-t-xs"></p>-->
          </app-label-wrapper>
          <app-label-wrapper label="Chave PIX para pagamento">
            <u-input class="app-input" hide-underline v-model="c('banco.integra.parcelamosTudo.chavePix').value"/>
          </app-label-wrapper>
          <app-label-wrapper label="Limite de Recebimento">
            <u-select :options="[{label: 'Somente comissão e taxas', value: 'comissao'},{label: 'Comissão, taxas + arremate em caso de repasse', value: 'totalRepasse'},{label: 'Valor total em todas as situações', value: 'total'}]" class="app-input" hide-underline v-model="c('banco.integra.parcelamosTudo.tipo').value" />
            <!--          <p class="app-legend m-t-xs"></p>-->
          </app-label-wrapper>
        </div>
      </div>
    </app-tab>
    <app-label-wrapper>
      <div class="m-t">
        <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
      </div>
    </app-label-wrapper>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import ListFormasPagamento from "@/components/cadastro/components/formaspagamento/List"
import {USelect, UInput} from "uloc-vue"
import CategoriaHirerarquiaSelect from "components/financeiro/components/input/CategoriaHirerarquiaSelect"
import Collapse from "components/globalconfig/components/include/Collapse"
import CodeEditor from "simple-code-editor"
import http from "@/utils/services/http"

export default {
  name: "ConfigFinanceiro",
  components: {
    CategoriaHirerarquiaSelect,
    ListFormasPagamento,
    USelect,
    UInput,
    Collapse,
    CodeEditor
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'formas-pagamento',
          label: 'Formas de Pagamento'
        },
        {
          name: 'automacao-pagamentos',
          label: 'Integração com Bancos'
        }
      ]
    },
    http () {
      return http
    },
    asaasUrlWebhook () {
      return http.defaults.baseURL + '/api/public/webhooks/financeiro/integrador?service=asaas'
    }
  }
}
</script>
